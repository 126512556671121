import React from "react";
import "./portfolio.css";
import IMG1 from "../../assets/portfolio1.jpg";
import IMG2 from "../../assets/portfolio2.jpg";
import IMG3 from "../../assets/portfolio3.jpg";
import IMG4 from "../../assets/portfolio4.jpg";
import * as Const from "./portfolio-constants";

const data = [
  {
    id: 1,
    image: IMG1,
    title: "Todo App - React",
    github: "https://github.com/bogdanjidovu/Todo-App-React",
    demo: "https://bogdanjidovu.github.io/Todo-App-React/",
  },
  {
    id: 2,
    image: IMG2,
    title: "IMDB Clone",
    github: "https://github.com/bogdanjidovu/IMDB-Angular",
  },
  {
    id: 3,
    image: IMG3,
    title: "Tic Tac Toe",
    github: "https://github.com/bogdanjidovu/TicTacToe-Angular",
    demo: "https://bogdanjidovu.github.io/TicTacToe-Angular/",
  },
  {
    id: 4,
    image: IMG4,
    title: "Todo App - Angular",
    github: "https://github.com/bogdanjidovu/Todo-App-Angular",
  },
];

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>{Const.PORTFOLIO_TITLE}</h5>
      <h2>{Const.PORTFOLIO_SUBTITLE}</h2>

      <div className="container portfolio__container">
        {data.map(({ id, image, title, github, demo }) => {
          return (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                <a href={github} className="btn" target="_blank">
                  {Const.GITHUB}
                </a>
                {demo ? (
                  <a href={demo} className="btn" target="_blank">
                    {Const.DEMO}
                  </a>
                ) : null}
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
