import React from "react";
import "./contact.css";
import { MdOutlineEmail } from "react-icons/md";
import { BsWhatsapp } from "react-icons/bs";
import { useRef } from "react";
import emailjs from "emailjs-com";
import * as Const from "./contact-constants";

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_it5wjdg",
      "template_xdu426t",
      form.current,
      "MULBlsBkP-onABS4p"
    );

    e.target.reset();
  };

  return (
    <section id="contact">
      <h5>{Const.CONTACT_TITLE}</h5>
      <h2>{Const.CONTACT_SUBTITLE}</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className="contact__option-icon"></MdOutlineEmail>
            <h4>{Const.EMAIL_TITLE}</h4>
            <h5>{Const.EMAIL_LINK}</h5>
          </article>
          <article className="contact__option">
            <BsWhatsapp className="contact__option-icon"></BsWhatsapp>
            <h4>{Const.WHATSAPP_TITLE}</h4>
            <h5>{Const.WHATSAPP_NUMBER}</h5>
            <a
              href="https://api.whatsapp.com/send?phone=40723160662"
              target="_blank"
            >
              {Const.MESSAGE}
            </a>
          </article>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            name="name"
            placeholder="Your Full Name"
            required
          />
          <input type="email" name="email" placeholder="Your Email" required />
          <textarea
            name="message"
            rows="7"
            placeholder="Your Message"
            required
          ></textarea>
          <button type="submit" className="btn btn-primary">
            {Const.MESSAGE}
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
