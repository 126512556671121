import React from "react";
import "./about.css";
import ME from "../../assets/me-about.png";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { FaFolderOpen } from "react-icons/fa";
import * as Const from "./about-constants";

const About = () => {
  return (
    <section id="about">
      <h5>Get to know</h5>
      <h2>About me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Me Image" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card card1">
              <FaAward className="about__icon" />
              <h5>{Const.EXPERIENCE_TITLE}</h5>
              <small>{Const.EXPERIENCE_YEARS}</small>
            </article>
            <article className="about__card card2">
              <FiUsers className="about__icon" />
              <h5>{Const.CLIENTS_TITLE}</h5>
              <small>{Const.CLIENTS_NUMBER}</small>
            </article>
            <article className="about__card card3">
              <FaFolderOpen className="about__icon" />
              <h5>{Const.PROJECTS_TITLE}</h5>
              <small>{Const.PROJECTS_NUMBER}</small>
            </article>
          </div>

          <p className="aboutMessage">{Const.ABOUT_MESSAGE}</p>

          <a href="#contact" className="btn btn-primary">
            {Const.LETS_TALK_TITLE}
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
