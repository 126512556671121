import React from "react";
import "./footer.css";
import { BsLinkedin } from "react-icons/bs";
import { GrGithub } from "react-icons/gr";
import * as Const from "./footer-constants";

const Footer = () => {
  return (
    <footer>
      <a href="#" className="footer__logo">
        {Const.LOGO}
      </a>

      <ul className="permalinks">
        <li>
          <a href="#">{Const.HOME}</a>
        </li>
        <li>
          <a href="#about">{Const.ABOUT}</a>
        </li>
        <li>
          <a href="#experience">{Const.EXPERIENCE}</a>
        </li>
        <li>
          <a href="#work">{Const.WORK}</a>
        </li>
        <li>
          <a href="#portfolio">{Const.PORTFOLIO}</a>
        </li>
        <li>
          <a href="#contact">{Const.CONTACT}</a>
        </li>
      </ul>

      <div className="footer__socials">
        <a href="https://www.linkedin.com/in/bogdanjidovu/" target="_blank">
          <BsLinkedin />
        </a>
        <a href="https://github.com/bogdanjidovu" target="_blank">
          <GrGithub />
        </a>
      </div>

      <div className="footer__copyright">
        <small>&copy;{Const.COPYRIGHT}</small>
      </div>
    </footer>
  );
};

export default Footer;
