import React from "react";
import CV from "../../assets/CV_BogdanJidovu.pdf";
import * as Const from "./header-constants";

const CTA = () => {
  return (
    <div className="cta">
      <a href={CV} download="CV_BogdanJidovu" className="btn">
        {Const.DOWNLOAD_TITLE}
      </a>
      <a href="#contact" className="btn btn-primary">
        {Const.CONTACT_TITLE}
      </a>
    </div>
  );
};

export default CTA;
