import React from "react";
import "./experience.css";
import { BsPatchCheckFill } from "react-icons/bs";
import * as Const from "./experience-constants";

const Experience = () => {
  return (
    <section id="experience">
      <h5>{Const.EXPERIENCE_TITLE}</h5>
      <h2>{Const.EXPERIENCE_SUBTITLE}</h2>
      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>{Const.FIRST_BLOCK_TITLE}</h3>
          <div className="experience__content">
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>{Const.FIRST_FIRST}</h4>
                <small className="text-light">{Const.INTERMEDIATE}</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>{Const.FIRST_SECOND}</h4>
                <small className="text-light">{Const.INTERMEDIATE}</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>{Const.FIRST_THIRD}</h4>
                <small className="text-light">{Const.EXPERIENCED}</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>{Const.FIRST_FOURTH}</h4>
                <small className="text-light">{Const.EXPERIENCED}</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>{Const.FIRST_FIFTH}</h4>
                <small className="text-light">{Const.INTERMEDIATE}</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>{Const.FIRST_SIXTH}</h4>
                <small className="text-light">{Const.INTERMEDIATE}</small>
              </div>
            </article>
          </div>
        </div>
        <div className="experience__backend">
          <h3>{Const.SECOND_BLOCK_TITLE}</h3>
          <div className="experience__content">
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>{Const.SECOND_FIRST}</h4>
                <small className="text-light">{Const.EXPERIENCED}</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>{Const.SECOND_SECOND}</h4>
                <small className="text-light">{Const.INTERMEDIATE}</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>{Const.SECOND_THIRD}</h4>
                <small className="text-light">{Const.INTERMEDIATE}</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>{Const.SECOND_FOURTH}</h4>
                <small className="text-light">{Const.BEGINNER}</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>{Const.SECOND_FIFTH}</h4>
                <small className="text-light">{Const.EXPERIENCED}</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>{Const.SECOND_SIXTH}</h4>
                <small className="text-light">{Const.BEGINNER}</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
