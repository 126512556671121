import React from "react";
import "./header.css";
import CTA from "./CTA";
import ME from "../../assets/me.png";
import HeaderSocials from "./HeaderSocials";
import * as Const from "./header-constants";

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h5>{Const.HELLO_MESSAGE}</h5>
        <h1>{Const.PERSONAL_NAME}</h1>
        <h5 className="text-light">{Const.PERSONAL_TITLE}</h5>
        <CTA />
        <HeaderSocials />

        <div className="me">
          <img src={ME} alt="" />
        </div>
      </div>
    </header>
  );
};

export default Header;
