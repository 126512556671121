export const EXPERIENCE_TITLE = "What Skills I Have";
export const EXPERIENCE_SUBTITLE = "Coding Experience";

export const EXPERIENCED = "Experienced";
export const INTERMEDIATE = "Intermediate";
export const BEGINNER = "Beginner";

export const FIRST_BLOCK_TITLE = "Frontend Development";
export const FIRST_FIRST = "React.js";
export const FIRST_SECOND = "Angular";
export const FIRST_THIRD = "JavaScript";
export const FIRST_FOURTH = "TypeScript";
export const FIRST_FIFTH = "HTML";
export const FIRST_SIXTH = "CSS";

export const SECOND_BLOCK_TITLE = "Backend Development";
export const SECOND_FIRST = "Java";
export const SECOND_SECOND = "Node.js";
export const SECOND_THIRD = "MySQL";
export const SECOND_FOURTH = "Spring";
export const SECOND_FIFTH = "C++";
export const SECOND_SIXTH = "MongoDB";

export const BLANK = "-";
