export const WORK_TITLE = "Done over the years";
export const WORK_SUBTITLE = "Work Experience";

export const EXPERIENCED = "Experienced";
export const INTERMEDIATE = "Intermediate";
export const BEGINNER = "Beginner";

export const FIRST_BLOCK_TITLE = "Jobs";
export const FIRST_FIRST = "Full stack Developer";
export const FIRST_FIRST2 = "@Thales Romania";
export const FIRST_SECOND = "Full stack Developer";
export const FIRST_SECOND2 = "@Questia Group";
export const FIRST_THIRD = "Software Developer Intern";
export const FIRST_THIRD2 = "@Questia Group";
export const FIRST_FOURTH = "Teaching Assistant";
export const FIRST_FOURTH2 = "@UPB";

export const SECOND_BLOCK_TITLE = "Achievements";
export const SECOND_FIRST = "Co-Organiser of Hacktoberfest Romania";
export const SECOND_SECOND = "Co-Organiser of USO Pop Quiz";
export const SECOND_THIRD = "Troubleshoot Helper at Linux Install Fest";
export const SECOND_FOURTH = "Participant at multiple coding contests";

export const BLANK = "-";
