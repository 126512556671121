import React from "react";
import "./work.css";
import { BiCheck } from "react-icons/bi";
import * as Const from "./work-constants";

const Work = () => {
  return (
    <section id="work">
      <h5>{Const.WORK_TITLE}</h5>
      <h2>{Const.WORK_SUBTITLE}</h2>
      <div className="container work__container">
        <div className="work">
          <h3>{Const.FIRST_BLOCK_TITLE}</h3>
          <ul className="work__list">
            <li>
              <BiCheck className="work__list-icon" />
              <p>{Const.FIRST_FIRST}</p>
              <small className="workplace">{Const.FIRST_FIRST2}</small>
            </li>
            <li>
              <BiCheck className="work__list-icon" />
              <p>{Const.FIRST_SECOND}</p>
              <small className="workplace">{Const.FIRST_SECOND2}</small>
            </li>
            <li>
              <BiCheck className="work__list-icon" />
              <p>{Const.FIRST_THIRD}</p>
              <small className="workplace">{Const.FIRST_THIRD2}</small>
            </li>
            <li>
              <BiCheck className="work__list-icon" />
              <p>{Const.FIRST_FOURTH}</p>
              <small className="workplace">{Const.FIRST_FOURTH2}</small>
            </li>
          </ul>
        </div>
        <div className="work">
          <h3>{Const.SECOND_BLOCK_TITLE}</h3>
          <ul className="work__list">
            <li>
              <BiCheck className="work__list-icon" />
              <p>{Const.SECOND_FIRST}</p>
            </li>
            <li>
              <BiCheck className="work__list-icon" />
              <p>{Const.SECOND_SECOND}</p>
            </li>
            <li>
              <BiCheck className="work__list-icon" />
              <p>{Const.SECOND_THIRD}</p>
            </li>
            <li>
              <BiCheck className="work__list-icon" />
              <p>{Const.SECOND_FOURTH}</p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Work;
